<template>
  <div>
    <v-switch v-model="userData.is_active" :loading="loading" @change="update" hide-details="" dense class="py-0 my-0 mx-auto"></v-switch>
  </div>
</template>

<script>
export default {
  props: ["userData"],
  data() {
    return {
      loading: false,
      oldData: {}
    }
  },
  methods: {
    async update() {
      if ((typeof this.userData.username !== "undefined") && (this.userData.username !== "")) {
        this.loading = true;
        var formData = new FormData();
        formData.append("username", this.userData.username);
        formData.append("is_active", this.userData.is_active ? 1 : 0);
        await this.$axios
          .post("user/set-active", formData)
          .then((res) => {
            this.loading = false;

            var resData = res.data;
            if (resData.status == "success") {
              this.showAlert(resData.status, resData.message);
            } else {
              this.showAlert(resData.status, resData.message);
            }
          })
          .catch((error) => {
            this.loading = false;
            this.axiosErrorHandler(error);

            this.data = this.oldData;
          });
      } else {
        this.showAlert("error", "Username is required!");
        this.data = this.oldData;
      }
    },
  },

  mounted() {
    this.oldData = this.userData
  }
}
</script>