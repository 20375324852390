<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>Edit User</v-card-title>
        <v-divider></v-divider>
        <div style="height: 500px; overflow-y: auto">
          <v-card-text>
            <v-text-field disabled v-model="username" :rules="usernameRules" label="Username" required></v-text-field>
            <v-text-field v-model="password" label="Password" type="password"></v-text-field>

            <v-text-field v-model="first_name" :rules="first_nameRules" label="First Name" required></v-text-field>
            <v-text-field v-model="last_name" label="Last Name"></v-text-field>
            <v-text-field disabled v-model="user_type" :rules="user_typeRules" label="User Type" required></v-text-field>
            <v-text-field v-model="user_level" :rules="user_levelRules" label="User Level" required></v-text-field>
            <v-text-field v-model="email" :rules="emailRules" label="Email" required></v-text-field>
            <v-text-field v-model="customer_no_" label="Customer No."></v-text-field>
            <v-text-field v-model="area" label="Area"></v-text-field>
            <v-text-field v-model="phone" label="Phone"></v-text-field>
          </v-card-text>
        </div>
        <v-card-actions class="align-end">
          <v-btn @click.stop="dialog = !dialog">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="reset"> clear </v-btn>
          <v-btn :disabled="!valid" color="primary" @click="saveData()"> submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      valid: true,
      first_name: "",
      last_name: "",
      user_type: "",
      user_level: "",
      email: "",
      username: "",
      customer_no_: "",
      area: "",
      phone: "",
      password: "",

      first_nameRules: [(v) => !!v || "First Name is required"],
      user_typeRules: [(v) => !!v || "User Type is required"],
      user_levelRules: [(v) => !!v || "User Level is required"],
      emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
      usernameRules: [(v) => !!v || "Username is required"],

      fnCallback: null,
      selectedUsername: null,
    };
  },

  methods: {
    show(username, fnCallback) {
      this.dialog = true;
      this.selectedUsername = username;
      this.fnCallback = fnCallback;

      this.getDetail();
    },
    hide() {
      if (this.fnCallback != "") {
        this.$emit(this.fnCallback);
      }
      this.dialog = false;
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
      this.getDetail();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getDetail() {
      this.showLoadingOverlay(true);
      var params = {
        username: this.selectedUsername,
      };
      this.$axios
        .get("user/data-detail", {
          params: params,
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          
          var resData = res.data;
          if (resData.status == "success") {
            this.tmpData = resData.data.user;
            this.first_name = resData.data.user.first_name;
            this.last_name = resData.data.user.last_name;
            this.user_type = resData.data.user.user_type;
            this.user_level = resData.data.user.user_level;
            this.email = resData.data.user.email;
            this.username = resData.data.user.username;
            this.customer_no_ = resData.data.user.customer_no_;
            this.area = resData.data.user.area;
            this.phone = resData.data.user.phone;
          }else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
        });
    },
    saveData() {
      this.validate();
      var d = this.$refs.form.validate();

      if (d == true) {
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("first_name", this.first_name);
        formData.append("last_name", this.last_name);
        formData.append("user_type", this.user_type);
        formData.append("user_level", this.user_level);
        formData.append("email", this.email);
        formData.append("username", this.username);
        formData.append("customer_no_", this.customer_no_);
        formData.append("area", this.area);
        formData.append("phone", this.phone);
        formData.append("password", this.password);
        this.$axios
          .post("user/update", formData)
          .then((res) => {
            this.showLoadingOverlay(false);
            var resData = res.data;
            if (resData.status == "success") {
              this.showAlert(resData.status, resData.message);

              if (this.fnCallback != "") {
                this.$emit(this.fnCallback);
              }
              this.hide();
            } else {
              this.$awn.alert("Internal Server Error");
            }
          })
          .catch((error) => {
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
    },
  },
};
</script>