<template>
  <div>
    <v-container fluid>
      <page-title title="User">
        <template slot="action">
          <v-btn color="primary" @click.stop="dialog1 = !dialog1">Add</v-btn>
        </template>
      </page-title>

      <v-row>
        <v-col>
          <v-card>
            <v-toolbar elevation="0">
              <v-toolbar-title>List Data</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-text-field rounded outlined dense clearable v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

              <v-btn icon @click="getData(true)" title="Refresh">
                <v-icon>mdi-sync</v-icon>
              </v-btn>
            </v-toolbar>
            <v-data-table :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" dense class="mb-3 my-0">
              <template v-slot:[`item.username`]="{ item }">
                <a href="javascript:;" @click="getDetail(item.username)">{{ item.username }}</a>
              </template>
              <template v-slot:[`item.is_active`]="{ item }">
                <!-- <v-switch v-model="item.is_active" hide-details="" dense class="py-0 my-0 mx-auto"></v-switch> -->
                <set-active-toogle :user-data="item"></set-active-toogle>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog1" max-width="300">
      <v-card>
        <v-card-title>Add New User</v-card-title>
        <v-card-text class="py-3">
          <v-btn class="mb-1" color="primary" text outlined block @click.stop="dialog1 = !dialog1, add()">Add New</v-btn>
          <v-btn class="mb-1" color="primary" text outlined block @click.stop="dialog1 = !dialog1, add('contact')">Add from Contact</v-btn>
          <v-btn class="mb-1" color="primary" text outlined block @click.stop="dialog1 = !dialog1, add('customer')">Add from Customer</v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text outlined @click.stop="dialog1 = !dialog1">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <UserAddDialog ref="refUserAddDialog"></UserAddDialog>
    <UserAddFromContactDialog ref="refUserAddFromContactDialog"></UserAddFromContactDialog>
    <UserAddFromCustomerDialog ref="refUserAddFromCustomerDialog"></UserAddFromCustomerDialog>
    <UserEditDialog ref="refUserEditDialog"></UserEditDialog>
    <UserDetailDialog ref="refUserDetailDialog">
      <template slot="action">
        <v-btn color="primary" @click="redirect('Setting.User.Edit', { 'u': selectedUsername })">Edit</v-btn>
      </template>
    </UserDetailDialog>
  </div>
</template>

<script>
import UserAddDialog from "@/components/user/UserAddDialog.vue";
import UserAddFromContactDialog from "@/components/user/UserAddFromContactDialog.vue";
import UserAddFromCustomerDialog from "@/components/user/UserAddFromCustomerDialog.vue";
import UserEditDialog from "@/components/user/UserEditDialog.vue";
import UserDetailDialog from "@/components/user/UserDetailDialog.vue";
import SetActiveToogle from './components/SetActiveToogle.vue';

export default {
  components: {
    UserDetailDialog,
    UserAddDialog,
    UserAddFromContactDialog,
    UserAddFromCustomerDialog,
    UserEditDialog,
    SetActiveToogle,
  },
  data() {
    return {
      search: "",
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        columns: [
          { text: "Username", value: "username" },
          { text: "Email", value: "email" },
          { text: "Firstname", value: "first_name" },
          { text: "Lastname", value: "last_name" },
          { text: "Type", value: "user_type" },
          { text: "Level", value: "user_level" },
          { text: "Is Active", value: "is_active" },
          { text: "Position", value: "employee_position" },
          { text: "Last Logged In", value: "last_login_date" },
        ],
        data: [],
      },
      selectedUsername: null,
      dialog1: null,
    };
  },

  methods: {
    getData(refresh) {
      if (refresh) this.AxiosStorageRemove("GET", "user/data");
      this.datatable.loading = true;
      var params = [];
      this.$axios
        .get("user/data", {
          params: params,
          cacheConfig: true,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.map(obj => {
              Object.keys(obj).map(key => {
                if (!isNaN(obj[key])) {
                  if (key == 'is_active')
                    obj[key] = +obj[key];
                }
              })
              return obj;
            });
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },

    getDetail(username) {
      this.$refs.refUserDetailDialog.getDetail(username);
      this.selectedUsername = username;
    },

    add(opt = '') {
      if (opt == '')
        this.$refs.refUserAddDialog.show("getData");
      if (opt == 'contact') {
        alert('In Progress!!!');
        // this.$refs.refUserAddFromContactDialog.show("getData");
      }
      if (opt == 'customer') {
        alert('In Progress!!!');
        // this.$refs.refUserAddFromCustomerDialog.show("getData");
      }
    },

    edit() {
      this.$refs.refUserDetailDialog.hide();

      this.$refs.refUserEditDialog.show(this.selectedUsername, "getData");
    },
  },

  mounted() {
    this.requiredLogin();
    this.getData();
  },
};
</script>