<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>Add User from Contact</v-card-title>
        <v-divider></v-divider>
        <div style="max-height: 500px; overflow-y: auto">
          <v-card-text>
            <v-text-field v-model="contactNo" :rules="contactNoRules" label="Contact No." required></v-text-field>
            <v-text-field v-model="password" :rules="passwordRules" label="Password" required type="password"></v-text-field>
          </v-card-text>
        </div>

        <v-card-actions class="align-end">
          <v-btn @click.stop="dialog = !dialog">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="reset">clear</v-btn>
          <v-btn :disabled="!valid" color="primary" @click="saveData()">submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      valid: true,
      contactNo: "",
      password: "",

      contactNoRules: [(v) => !!v || "Contact No. is required"],
      passwordRules: [(v) => !!v || "Password is required"],

      fnCallback: null,
    };
  },

  methods: {
    show(fnCallback) {
      this.dialog = true;
      this.fnCallback = fnCallback;
    },
    hide() {
      if (this.fnCallback != "") {
        this.$emit(this.fnCallback);
      }
      this.dialog = false;
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    saveData() {
      this.validate();
      var d = this.$refs.form.validate();

      if (d == true) {
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("contact_no", this.contactNo);
        formData.append("password", this.password);
        this.$axios
          .post("user/save-from-contact", formData)
          .then((res) => {
            this.showLoadingOverlay(false);
            var resData = res.data;
            if (resData.status == "success") {
              this.showAlert(resData.status, resData.message);
              this.hide();
            } else {
              this.showAlert(resData.status, resData.message);
            }
          })
          .catch((error) => {
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
    },
  },
};
</script>